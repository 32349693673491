import React from 'react';
import PropTypes from 'prop-types';
import Content from '../components/Content';

import seaweedMap from '../../static/img/seaweed_map.jpg';

export default function AboutPageTemplate({ title, content, contentComponent }) {
  const PageContent = contentComponent || Content;

  return (
    <>
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: 'url("/img/home-jumbotron.jpg")',
        background: 'linear-gradient(0deg, rgba(0,0,0,.7), rgba(0,0,0,.3)), url("/img/home-jumbotron.jpg")',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'bottom left',
      }}
    >
      <h1
        className="has-text-weight-bold is-size-1"
        style={{
          color: 'white',
          padding: '1rem',
        }}
      >
        About
      </h1>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <img src={seaweedMap} style={{ borderRadius: 5, marginTop: 10, marginBottom: 10 }} alt="Seaweed Map" />
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};
